<i18n>
{
  "en": {
    "upgrade": "Upgrade",
    "join": "Join",
    "reviews": "User Reviews"
  },
  "ja": {
    "upgrade": "アップグレード",
    "join": "入会する",
    "reviews": "ユーザーレビュー"
  }
}
</i18n>

<template>
<main class="movies">
  <vue-headful
    :title="dynamicTitle"
    :head="{
        'meta[name=\'robots\']': {
          name: 'robots',
          content: (guestExcluded) ? 'noindex' : 'index'
        },
    }" />

  <!-- video player -->
  <video-player v-if="!guestExcluded" :key="componentRefresh"/>

  <!-- chapters -->
  <movie-chapters />

  <!-- movie options area (playlist, vrack download) -->
  <movie-options />

  <div class="contents">
    <!-- movie info -->
    <movie-info :hide-release-date="hideOldReleaseDate" />

    <!-- movie download -->
    <download-menu v-if="!newXPEligible" />

    <!-- movie gallery -->
    <movie-gallery v-if="!guestExcluded" />

    <!-- related movies -->
    <related-movies :num-movies="6" />

    <!-- movie reviews -->
    <div v-if="locale == 'ja'" class="movie-review section" id="user-reviews">
      <h2>{{ $t('reviews') }}</h2>
      <review-form />
      <review-list />
    </div>

    <!-- aff embed -->
    <div v-if="hasCensoredSample">
      <aff-embed />
    </div>

  </div><!-- /.contents -->
</main>
</template>

<script>
/* eslint max-len: 0 */

import vueHeadful from 'vue-headful';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import APP_CONFIG from '@/appConfig';
import VideoPlayer from '@/components/movieDetail/videoPlayer.vue';
import MovieOptions from '@/components/movieDetail/movieOptions.vue';
import MovieInfo from '@/components/movieDetail/movieInfo.vue';
import DownloadMenu from '@/components/movieDetail/downloadMenu.vue';
import MovieGallery from '@/components/movieDetail/movieGallery.vue';
import ReviewForm from '@/components/movieDetail/reviewForm.vue';
import ReviewList from '@/components/movieDetail/reviewList.vue';
import RelatedMovies from '@/components/movieDetail/relatedMovies.vue';
import AffEmbed from '@/components/movieDetail/affEmbed.vue';
import Chapters from '@/components/movieDetail/chapters.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'video-player': VideoPlayer,
    'movie-info': MovieInfo,
    'download-menu': DownloadMenu,
    'movie-gallery': MovieGallery,
    'review-form': ReviewForm,
    'review-list': ReviewList,
    'related-movies': RelatedMovies,
    'aff-embed': AffEmbed,
    'movie-chapters': Chapters,
    'movie-options': MovieOptions,
  },
  data() {
    return {
      movie: {},
      dynamicTitle: '',

      componentRefresh: 0,
    };
  },
  async mounted() {
    await this.getMovie(this.userTypeID);
  },
  methods: {
    async getMovie(userTypeID) {
      const bfAPI = new BifrostAPI();

      const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
      this.movie = MovieHelper.expandDetails(movie, this.locale, userTypeID);

      // redirect to home if the movie's no_list_display flag is set
      if (Object.prototype.hasOwnProperty.call(this.movie, 'NoListDisplay')
        && this.movie.NoListDisplay) this.$router.push({ name: 'home' });

      // set browser title
      const actors = (this.locale === 'en') ? this.movie.ActressesEn.join() : this.movie.ActressesJa.join();
      const title = (this.locale === 'en') ? this.movie.TitleEn : this.movie.Title;
      this.dynamicTitle = `${actors} ${title} ${APP_CONFIG.site.title[this.locale]}`;

      // we have the title, now send the page view
      this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
    },

    forceRerender() {
      // setting a reactive counter hooked up to the `key` attribute for the video-plahyer
      // component in the template. vue knows to re-render a component if it has a `key` attribute
      // bound to a reactive value, so we're taking advantage of this in order to force the player
      // to refresh whenever the user logs in -- this fixes a bug where two GA video player events
      // would be sent if a user logs in (or out) while on a movie detail page
      // (from https://michaelnthiessen.com/force-re-render/
      setTimeout(() => {
        this.componentRefresh += 1;
      }, 650);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    guestExcluded() {
      return (this.userTypeID === 0 && this.movie.isGuestExcluded === true);
    },
    hasCensoredSample() {
      const releaseEpoch = Date.parse(this.movie.Release) / 1000;
      return (releaseEpoch >= 1489622400); // march 6 2017
    },
    hideOldReleaseDate() {
      const releaseDate = new Date(this.movie.Release);
      const currentDate = new Date();
      const differenceInMsec = currentDate - releaseDate;
      const differenceInDays = differenceInMsec / (1000 * 60 * 60 * 24); // milliseconds to days
      return (differenceInDays > APP_CONFIG.site.releaseDateHiddenAge);
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getMovie(newVal);
      this.forceRerender();
    },
  },
};
</script>

<style lang="scss">
// this block needs to be 'scoped' because one of the global styles is causing the timeslider
// previews to not render correctly (shows the entire sprite instead of the frame)
// 10/27/20 annie notes: i tried scoped this but there are layout issues so i left it unscoped,
// but the timeslider previews seem working fine, maybe this issue doesn't apply to 1p site
@import '@/assets/sass/pages/_detail.scss';
</style>
