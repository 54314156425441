<i18n>
{
  "en": {
    "sl_explainer": "To continue watching sample movies, <br>you need to sign up for a free membership.",
    "sl_btn_join_free": "Free Sign Up",
    "sl_btn_join_site": "Sign Up for 1pondo"
  },
  "ja": {
    "sl_explainer": "引き続きサンプル動画を視聴いただくには<br>無料会員登録が必要です。",
    "sl_btn_join_free": "無料会員登録",
    "sl_btn_join_site": "一本道への入会はこちら"
  }
}
</i18n>

<template>
  <div class="player-wrap hero">
    <div class="player" v-if="movieDetail.MovieID" v-sfw>
      <!-- the video player -->
      <video v-if="!noPlayer && !sampleLimit" class="video-js vjs-big-play-centered" id="video-player-0" playsinline>
        <track kind="metadata" :src="`/assets/ts/${movieDetail.MovieID}/thumbnail.vtt`" />
      </video>

      <!-- sample playback is reached -->
      <div v-else-if="sampleLimit">
        <!-- sample limit overlay -->
        <div v-if="limitOverlayVisible" class="sample-limit-modal">
          <div class="modal-close">
            <span class="modal-close-icon" @click="hideSampleLimit()"></span>
          </div>
          <div class="modal-container">
            <div class="modal-body" v-html="$t('sl_explainer')"></div>
            <div class="modal-footer">
              <a :href="getFreeJoinLink()"
                target="_blank"
                rel="noopener">
                <button class="button-fill button-xlarge sl-cta--free">{{ $t('sl_btn_join_free') }}</button>
              </a>
              <button class="button-large sl-cta--1p" @click="gotoJoin()">{{ $t('sl_btn_join_site') }}&ensp;<i class="iconf-arrow-right"></i></button>
            </div>
          </div>
        </div>
        <!-- fake player -->
        <div class="video-js vjs-big-play-centered vjs-paused vjs-fluid vjs-v7" @click="showSampleLimit()">
          <div class="vjs-poster" :style="`background-image: url(${movieDetail.posterSrc})`"></div>
          <button class="vjs-big-play-button" type="button" title="Play Video">
            <span class="vjs-icon-placeholder"></span>
          </button>
        </div>
      </div>

      <!-- movie poster for expired movies (and edge cases) -->
      <img class="player-poster"
        v-else-if="noPlayer"
        :src="movieDetail.posterSrc" @error="useFallbackImage()">
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
/* eslint max-len: 0 */
/* eslint no-unused-vars: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';
import VideoJS from '@/assets/js/services/Video/VideoJS';
import PlayerFactory from '@/assets/js/services/Video/PlayerFactory';
import Analytics from '@/assets/js/utils/Analytics';
import MovieHelper from '@/assets/js/utils/movie';
import APP_CONFIG from '@/appConfig';

const VR_CATEGORY_ID = 94;
const SAMPLE_PLAY_LIMIT = APP_CONFIG.site.samplePlayback.limit || 5;

export default {
  props: {
    checkDate: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      videoPlayerInstance: null,
      movieDetail: {},
      oldMovie: false,
      limitOverlayVisible: false,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    noPlayer() {
      /*
        show only movie poster (don't even load the video player) if any of the folowing is true:
          - movie is expired
          - memberFiles data is missing from the details json
          - movie is VR and user is a streaming or guest user type
          - oldMovie && user is not a member
      */
      return (
        this.movieDetail.IsExpired
          || !this.movieDetail.MemberFiles
          || this.showVRPoster
          || (this.oldMovie && !this.userIsSiteMember)
      );
    },
    showVRPoster() {
      return (this.$store.getters['user/isNewXPEligible']
        && this.movieDetail.UC.includes(VR_CATEGORY_ID)
        && process.env.VUE_APP_NEW_XP_HIDE_VR === 'true');
    },
    sampleLimit() {
      return (false && this.$store.getters['prefs/playedSamples'].length >= SAMPLE_PLAY_LIMIT
        && !this.$store.getters['prefs/playedSamples'].includes(this.$route.params.movieId));
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    userTypeStr() {
      return this.$store.getters['user/typeStr'];
    },
    userIsRecurring() {
      return this.$store.getters['user/isRecurring'];
    },
    userIsAnnual() {
      return this.$store.getters['user/isAnnual'];
    },
    userIsTrial() {
      return this.$store.getters['user/isTrial'];
    },
  },

  // note: reloading of the video player is now handled on the component containing this, in order
  // to fix timing issues with login + video player initializing causing double video player events
  // being sent to GA (due to the player initializing twice for logging in at specific times during
  // the page loading cycle)

  methods: {
    playerInstanceFactory(movieData) {
      const userData = {
        typeID: this.userTypeID,
        typeStr: this.userTypeStr,
        isSiteMember: this.userIsSiteMember,
        isTrial: this.userIsTrial,
        isRecurring: this.userIsRecurring,
        isAnnual: this.userIsAnnual,
      };
      PlayerFactory.register('VideoJS', () => new VideoJS('video-player-0', movieData, userData, this.locale, APP_CONFIG.site.enThumbsStartEpoch));

      const init = (Factory) => {
        this.videoPlayerInstance = Factory.create('VideoJS');
        // player class methods can be accessed here.
        // example (assuming ack() method in the videojs class): videoPlayerInstance.ack('hello');
      };

      init(PlayerFactory);
    },

    useFallbackImage() {
      // if poster_en.jpg is missing, use str.jpg
      this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/str.jpg`);
    },

    getFreeJoinLink() {
      return `${APP_CONFIG.site.samplePlayback.upsellURLs[this.locale]}&gtm=${process.env.VUE_APP_GTM_ID}`;
    },

    // modal functions
    showSampleLimit() {
      this.limitOverlayVisible = true;

      Analytics.sendCustomEvent('sample_limit', {
        action: 'Sample Limit',
      });
    },

    hideSampleLimit() {
      this.limitOverlayVisible = false;
    },

    gotoJoin() {
      this.$modal.hide('modal-sample-limit');
      this.$router.push({ name: 'join' });
    },


  },
  async mounted() {
    // get playlist callback, this fires first then calls the player instance factory to start a new
    // video player instance
    const bfAPI = new BifrostAPI();

    const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
    this.movieDetail = MovieHelper.expandDetails(movie);

    // check if we're passing a check-date param; in which we need to not show sample playback
    // movies for non-members, and make it appear like an expired movie (only show the movie poster)
    if (this.checkDate) {
      const releaseEpoch = Date.parse(this.movieDetail.Release) / 1000;
      this.oldMovie = (releaseEpoch < Number(this.checkDate));
    }

    // set intial movie poster image as 'posterSrc'
    if (this.locale === 'ja') {
      this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/str.jpg`);
    } else {
      this.$set(this.movieDetail, 'posterSrc', `/assets/sample/${this.movieDetail.MovieID}/poster_en.jpg`);
    }

    if (!this.noPlayer && !this.sampleLimit) this.playerInstanceFactory(this.movieDetail);
  },
  beforeDestroy() {
    // remove videoplayer instance before leaving the page
    if (this.videoPlayerInstance) this.videoPlayerInstance.remove();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/pages/_detail.scss';

// modal

.sample-limit-modal {
  background: $color-black;
  bottom: 0;
  display: grid;
  left: 0;
  position: absolute;
  place-content: center;
  right: 0;
  top: 0;
  z-index: 10;

  @include mq(phone) {
    margin-right: 24px;
    margin-left: 24px;
  }

  @include mq(desktop) {
    margin-right: 32px;
    margin-left: 32px;
  }

  // modal - close

  .modal-close {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .modal-close-icon {
    display: block;
    height: 44px;
    position: relative;
    width: 44px;

    &::before,
    &::after {
      content: "";
      background: $color-white-light;
      bottom: auto;
      height: 2px;
      left: 50%;
      position: absolute;
      right: auto;
      top: 50%;
      width: 24px;

      @media screen and (min-width: 592px) and (min-height: 560px) {
        width: 20px;
      }
    }

    &::before {
      transform: translate(-50%,-50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%,-50%) rotate(-45deg);
    }
  }

  // modal - container

  .modal-container {
    padding: 16px 32px;
    text-align: center;
  }

  // modal - body

  .modal-body {
    color: rgba(255, 255, 255, .9);
  }

  // modal - footer

  .modal-footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 18px;

    button {
      border-radius: 100px;
    }
    .sl-cta--free {
      background: #66CC00;
      color: $color-white;
      font-size: 16px;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: darken(#66CC00, 5%);
        }
      }
    }

    .sl-cta--1p {
      color: $color-blue;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
