<i18n>
{
  "en": {
    "vip_badge": "VIP",
    "svip_badge": "SVIP",
    "annual_badge": "Annual",
    "limited_tag": "Limited Availability",
    "expired_tag": "Availability Ended",
    "future_tag": "Upcoming",
    "vr_tag": "VR",
    "rating": "User Rating",
    "reviews": "{numReviews} reviews | {numReviews} review | {numReviews} reviews",
    "release": "Release Date",
    "duration": "Duration",
    "performers": "Featuring",
    "age": "Age",
    "series": "Series",
    "tags": "Tags",
    "view_details": "View Details"
  },
  "ja": {
    "vip_badge": "VIP",
    "svip_badge": "超VIP",
    "annual_badge": "年間",
    "limited_tag": "期間限定",
    "expired_tag": "配信期間終了",
    "future_tag": "近日配信",
    "vr_tag": "VR",
    "rating": "ユーザー評価",
    "reviews": "{numReviews}件のレビュー",
    "release": "配信日",
    "duration": "再生時間",
    "performers": "出演",
    "age": "年齢",
    "series": "シリーズ",
    "tags": "タグ",
    "view_details": "もっと見る"
  }
}
</i18n>

<template>
<div class="movie-info section divider">
  <div class="movie-overview">
    <div class="movie-tag">
      <div v-if="movie.IsExpired" class="tag-status tag-status--expired">{{ $t('expired_tag') }}</div>
      <div v-if="movie.IsLimited" class="tag-status tag-status--limited">{{ $t('limited_tag') }}</div>
      <div v-if="movie.IsComingSoon" class="tag-status tag-status--future">{{ $t('future_tag') }}</div>
      <div v-if="movie.isVR" class="tag-status tag-status--vr">{{ $t('vr_tag') }}</div>
      <div v-if="!newXPEligible && movie.isVIP" class="tag-member tag-member--vip">{{ $t('vip_badge') }}</div>
      <div v-if="!newXPEligible && movie.isSVIP" class="tag-member tag-member--svip">{{ $t('svip_badge') }}</div>
      <div v-if="!newXPEligible && movie.isAnnual" class="tag-member tag-member--annual">{{ $t('annual_badge') }}</div>
    </div>
    <h1 class="h1--dense" v-if="locale == 'ja'">{{ movie.Title }}</h1>
    <h1 class="h1--dense" v-if="locale == 'en'">
      <span v-if="movie.TitleEn != null && movie.TitleEn != ''">{{ movie.TitleEn }}</span>
      <span v-else v-for="(actress, index) in movie.ActressesEn" :key="actress.$index">{{ actress }}<span v-if="index + 1 < movie.ActressesEn.length">,&nbsp;</span></span>
    </h1>
    <div>
      <star-rating v-model="movieReviews.AvgRating" active-color="#FEB507" :increment="0.1" :star-size="16" :inline="true" :show-rating="false" :read-only="true"></star-rating>
      <span class="user-count" v-if="locale == 'ja'"><a href="#user-reviews" v-smooth-scroll="{ duration: 500, offset: -(appHeaderHeight) }">{{ $tc('reviews', numReviews, { numReviews }) }}</a></span>
      <span class="user-count" v-if="locale == 'en'">{{ $tc('reviews', numReviews, { numReviews }) }}</span>
    </div>
  </div>
  <button class="button-flat button-medium button-icon--right see-more" v-if="!showDescription" @click="showDescription = true"><label for="movie-detail">{{ $t('view_details') }}<svg class="tm-icon"><use xlink:href="#tm-icon-arrow-drop-down"/></svg></label></button>
  <transition name="movie-info">
    <div class="movie-detail" v-if="showDescription">
      <p v-if="locale == 'ja'">{{ movie.Desc }}</p>
      <p v-if="locale == 'en' && movie.DescEn != null && movie.DescEn != ''">{{ movie.DescEn }}</p>
      <ul>
        <li class="movie-detail__spec" v-if="!hideReleaseDate">
          <span class="spec-title">{{ $t('release') }}:</span>
          <span class="spec-content">{{ movie.ReleaseDate }}<span v-if="movie.ExpireDate"> &ndash; {{ movie.ExpireDate }} <span v-if="!movie.IsExpired">{{ movie.ExpireTime }}</span></span></span>
        </li>
        <li class="movie-detail__spec">
          <span class="spec-title">{{ $t('performers') }}:</span>
          <span class="spec-content"><span v-for="(props, actressId) in movie.ActressesList" :key="actressId"><router-link :to="`/search/?a=${actressId}`">{{ (locale == 'en') ? props.NameEn : props.NameJa }}</router-link>&nbsp;</span></span>
        </li>
        <li class="movie-detail__spec" v-if="movie.SeriesID">
          <span class="spec-title">{{ $t('series') }}:</span>
          <span class="spec-content"><router-link :to="'/search/?sr=' + movie.SeriesID">{{ (locale == 'en') ? movie.SeriesEn : movie.Series }}</router-link></span>
        </li>
        <li class="movie-detail__spec">
          <span class="spec-title">{{ $t('duration') }}:</span>
          <span class="spec-content">{{ movie.DurationFmt }}</span>
        </li>
        <li class="movie-detail__spec">
          <span class="spec-title">{{ $t('tags') }}:</span>
          <span class="spec-content">
            <span class="spec-content" v-for="(props, UC) in categoryList" :key="UC">
              <router-link :to="`/search/?c=${UC}`" class="spec__tag">{{ (locale === 'ja') ? movie.UcNameList[UC].NameJa : movie.UcNameList[UC].NameEn }}</router-link>
            </span>
          </span>
        </li>
      </ul>
    </div>
  </transition>
</div>
</template>

<script>
/* eslint max-len: 0 */
import StarRating from 'vue-star-rating';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import APP_CONFIG from '@/appConfig';

export default {
  props: {
    hideReleaseDate: Boolean,
  },
  components: {
    'star-rating': StarRating,
  },
  data() {
    return {
      movie: {},
      movieKey: '',
      movieReviews: { AvgRating: 0 },
      showDescription: false,
      categoryMap: {},
    };
  },
  async created() {
    // non-reactive consts
    this.appHeaderHeight = APP_CONFIG.site.headerHeight;

    const bfAPI = new BifrostAPI();
    const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
    this.movie = MovieHelper.expandDetails(movie);
    if (this.movie.MetaMovieID && this.userIsSiteMember) {
      bfAPI.ReqMovieKeys(this.movie.MetaMovieID).then((movieKeys) => {
        this.movieKey = movieKeys.KeyMercury;
      });
    }

    const reviews = await bfAPI.getMovieReviews(this.$route.params.movieId);
    if (reviews) this.movieReviews = reviews;
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEligible() {
      return this.$store.getters['user/isNewXPEligible'];
    },
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    numReviews() {
      return (Object.prototype.hasOwnProperty.call(this.movieReviews, 'Rows')) ? this.movieReviews.Rows.length : 0;
    },
    categoryList() {
      // show categories, depending on if the user is new XP eligible; if they are, don't show the
      // tier categories (VIP, SVIP, Annual)
      const HIDDEN_CATS_FOR_CLASSIC = ['82', '83'];
      const HIDDEN_CATS_FOR_NEW_XP = ['82', '83', '50000', '50001', '50003'];
      const categoryIDs = this.movie.UcNameList ? Object.keys(this.movie.UcNameList)
        .filter(id => ((!this.newXPEligible && !HIDDEN_CATS_FOR_CLASSIC.includes(id)) || (this.newXPEligible && !HIDDEN_CATS_FOR_NEW_XP.includes(id))))
        .reduce((obj, key) => Object.assign(obj, { [key]: this.movie.UcNameList[key] }), {}) : null;
      return categoryIDs;
    },
  },
  watch: {
    async userIsSiteMember(newVal) {
      if (newVal) {
        // logged in; get movie keys
        const bfAPI = new BifrostAPI();
        const movieKeys = await bfAPI.ReqMovieKeys(this.movie.MetaMovieID);
        this.movieKey = movieKeys.KeyMercury;
      } else {
        // logged out; clear movie keys
        this.movieKey = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.movie-info-enter-active, .movie-info-leave-active {
  transition: opacity 1s;
}
.movie-info-enter, .movie-info-leave-to {
  opacity: 0;
}
</style>
