<i18n>
{
  "en": {
    "chapters": "Chapters",
    "close": "Close"
  },
  "ja": {
    "chapters": "チャプター",
    "close": "閉じる"
  }
}
</i18n>

<template>
<div class="movie-chapter" v-if="userIsSiteMember && movieChapters">
  <div class="chapter-contents">

    <!-- chapters - close -->
    <transition name="fade">
    <div class="chapter-content--close" v-if="!showChapters" @click="showChapters = true">
      <transition name="movie-chapter">
        <div class="chapter-button--open"><svg class="tm-icon"><use xlink:href="#tm-icon-keyboard-arrow-down"/></svg>{{ $t('chapters') }}</div>
      </transition>
    </div>
    </transition>

    <!-- chapters - open -->
    <transition name="slide">
    <div class="chapter-content--open" v-if="showChapters">
      <div class="chapter-button--close" @click="showChapters = false"><svg class="tm-icon"><use xlink:href="#tm-icon-keyboard-arrow-up"/></svg>{{ $t('close') }}</div>
      <div class="chapter-overflow">
        <div class="chapter-container">
          <div class="chapter-item" v-for="chapter in movieChapters" :key="chapter.TimestampID">
            <div class="chapter-figure">
              <div
                v-if="chapterStyles[chapter.Seconds]"
                class="vtt-sprite"
                :style="chapterStyles[chapter.Seconds]"
                @click="setPlayerTime(chapter.Seconds)"
                v-sfw />
            </div>
            <div class="chapter-info" @click="setPlayerTime(chapter.Seconds)">
              <div class="chapter-info--timestamp">{{ secondsToHMS(chapter.Seconds) }}</div>
              <div class="chapter-info--description">{{ (locale === 'en') ? chapter.DescEn : chapter.DescJa }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
import webvtt from 'node-webvtt';
import axios from 'axios';
import EventBus from '@/assets/js/utils/EventBus';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';

const bfAPI = new BifrostAPI();

export default {
  data() {
    return {
      movieId: this.$route.params.movieId,
      movieDetail: {},
      chapterEligible: false,
      showChapters: false,
      movieChapters: null,
      chapterStyles: {},
    };
  },
  async created() {
    const movieDetail = await bfAPI.getMovieDetail(this.movieId);
    this.movieDetail = MovieHelper.expandDetails(movieDetail);

    this.checkIfEligible();
  },
  methods: {
    async checkIfEligible() {
      // only show chapers if the user can stream the full movie
      // streaming users can play all movies, download users will need an additional check
      this.chapterEligible = (this.userClass === 'Streaming');

      if (this.userClass === 'Download') {
        const keys = await bfAPI.ReqMovieKeys(this.movieDetail.MetaMovieID);
        if (Object.prototype.hasOwnProperty.call(keys, 'KeyMercury') && keys.KeyMercury) this.chapterEligible = true;
      }
    },
    async getChapters() {
      if (this.movieDetail.MetaMovieID) {
        const chapterData = await bfAPI.getMovieChapters(this.movieDetail.MetaMovieID);
        console.log('%c chapterData', 'color:#fff', chapterData);
        this.movieChapters = (chapterData.length > 0) ? chapterData : null;
        this.buildThumbnailMap();
      }
    },
    buildThumbnailMap() {
      axios.get(`/assets/ts/${this.movieId}/thumbnail.vtt`).then((response) => {
        if (response.data !== null) {
          const vtt = webvtt.parse(response.data);
          const parsedVTT = (vtt.valid) ? vtt.cues : [];
          // walk thru thumbnails and find vtt cue
          this.movieChapters.forEach((chapter) => {
            const cue = parsedVTT.filter(item => (chapter.Seconds >= item.start && chapter.Seconds <= item.end));
            // we could have 2 VTT cues that match the seconds; we only need the 1st match
            this.$set(this.chapterStyles, chapter.Seconds, this.getVttCss(cue[0].text));
          });
        }
      }).catch((error) => {
        console.log('%cerror getting VTT', 'background: #222; color: #f00', error);
      });
    },
    setPlayerTime(seconds) {
      // scroll up to top (video player) -- tried scroll to the player's div id but the toolbar throws it off
      window.scrollTo(0, 0);
      // send an event to trigger listener in VideoJS service
      EventBus.$emit('videoplayer:event:setPlayerTime', seconds);
    },
    secondsToHMS(seconds) {
      // less than 1 hour: show MM:SS, more than 1 hour, show HH:MM:SS
      return (seconds < 3600) ? new Date(seconds * 1000).toISOString().substr(14, 5) : new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    getVttCss(cue) {
      // builds css needed to display the chapter thumbnail; shamelessly borrowed from
      // https://github.com/chrisboustead/videojs-vtt-thumbnails/blob/master/src/plugin.js
      const cssObj = {};

      const THUMB_RESIZE = 0.6; // shrink thumbnails to 60% of original size

      const imageDefSplit = cue.split(/#xywh=/i);
      const imageUrl = imageDefSplit[0];
      const imageCoords = imageDefSplit[1];
      const splitCoords = imageCoords.match(/[0-9]+/gi);

      const imageProps = {
        x: splitCoords[0],
        y: splitCoords[1],
        w: splitCoords[2],
        h: splitCoords[3],
        image: imageUrl,
      };

      cssObj['background-image'] = `url('/assets/ts/${this.movieId}/${imageProps.image}')`;
      cssObj['background-position'] = `-${imageProps.x * THUMB_RESIZE}px -${imageProps.y * THUMB_RESIZE}px`;

      return cssObj;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    userClass() {
      return this.$store.getters['user/class'];
    },
  },
  watch: {
    userIsSiteMember(newVal, oldVal) {
      if (newVal !== oldVal) this.checkIfEligible();
    },
    userClass(newVal, oldVal) {
      if (newVal !== oldVal) this.checkIfEligible();
    },
    chapterEligible(newVal, oldVal) {
      if (!oldVal && newVal) this.getChapters();
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
   transition-duration: 0.3s;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   transition-duration: 0.3s;
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
}
</style>
