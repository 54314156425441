<i18n>
{
  "en": {
    "download": "Download",
    "signup": "Sign Up",
    "upgrade": "Upgrade",
    "ddl_notice": "Daily Download Limit reached. Please wait until it has cleared, or upgrade your Daily Download Limit if you would like to download more.",
    "upgrade_ddl": "UPGRADE",
    "close_notification": "CLOSE",
    "mdl_title_confirm": "Confirm",
    "mdl_text_confirm_0": "You will have ",
    "mdl_text_confirm_1": " downloads left after this download. Proceed?",
    "mdl_btn_cancel": "Cancel"
  },
  "ja": {
    "download": "ダウンロード",
    "signup": "新規会員登録",
    "upgrade": "アップグレード方法",
    "ddl_notice": "お客様のダウンロード転送量は上限へ達しました。再びアクセス可能となるまでしばらくお待ちください。(※ダウンロード転送量制限の解除は、日本時間の午前5時となります。)",
    "upgrade_ddl": "UPGRADE",
    "close_notification": "CLOSE",
    "mdl_title_confirm": "ダウンロード確認",
    "mdl_text_confirm_0": "本日のダウンロードが残り",
    "mdl_text_confirm_1": "本になりますがよろしいですか？",
    "mdl_btn_cancel": "キャンセル"
  }
}
</i18n>

<template>
<div>
  <!-- non-member / guest, link to login or go to /join/ -->
  <div v-if="!downloadEligible && !userIsSiteMember" class="movie-download section divider">
    <h2>{{ $t('download') }}</h2>
    <div class="download-guest">
      <p v-if="locale == 'ja'">動画本編の視聴およびダウンロードには<a href="javascript:void(0)" @click="showLogin()">ログイン</a>が必要です。まだ会員登録がお済みでない方は下記ボタンから入会案内ページへ進み、会員登録を行なってください。</p>
      <p v-if="locale == 'en'">Please <a href="javascript:void(0)" @click="showLogin()">login</a> in order to download. If you are not a member, please click on the button below to register.</p>
      <router-link to="/join/"><button class="button-fill button-default button-large">{{ $t('signup') }}</button></router-link>
    </div>
  </div>

  <!-- member -->
  <div v-if="userIsSiteMember" class="movie-download section divider">
    <div class="heading">
      <h2>{{ $t('download') }}</h2>
      <span v-if="userIsSiteMember && movieDetail.isVR && this.locale == 'ja'"><router-link to="/faq/#faq-1" class="button button-flat button-medium">VR動画の視聴方法はこちら</router-link></span>
      <span v-if="this.ua.browser.name == 'IE' && this.locale == 'ja'"><router-link to="/faq/#faq-movie" class="button button-flat button-medium">ダウンロードができませんか？</router-link></span>
    </div>
    <div class="download-member">

      <div class="download-section" :class="{ 'is-disabled': disableDownload }" v-for="file in memberFiles" :key="file.FileName">
        <!-- minimized download button -->
        <!-- if movie has NOT been downloaded before, click to show confirm dialog -->
        <a v-if="!isDownloaded && !movieDetail.IsExpired && !userIsLimited && (downloadEligible && userClass === 'Download')"
          class="invisible-cta"
          @click="showConfirmModal(file.FileName, file.downloadUrl, $event)">
        </a>
        <!-- if movie has been downloaded before, click to download the movie directly -->
        <a v-if="isDownloaded && !movieDetail.IsExpired && !userIsLimited && (downloadEligible && userClass === 'Download')"
          class="invisible-cta" :href="file.downloadUrl"
          @click="downloadClick(file.FileName)" download>
        </a>
        <a v-if="disableDownload" class="invisible-cta"></a>
        <div class="quality">{{ infoMap[file.FileName].display }}<span class="quality__note">{{ infoMap[file.FileName].note }}</span></div>
        <div class="filesize">{{ printBytes(file.FileSize) }}</div>
        <div class="cta">
          <svg class="tm-icon tm-icon--download"><use xlink:href="#tm-icon-download"/></svg>
          <!-- regular download button -->
          <!-- if movie has NOT been downloaded before, click to show confirm dialog -->
          <button v-if="!isDownloaded && !movieDetail.IsExpired && !userIsLimited && (downloadEligible && userClass === 'Download')"
            @click="showConfirmModal(file.FileName, file.downloadUrl, $event)"
            class="button button-fill button-default button-medium button-icon--left">
            <svg class="tm-icon"><use xlink:href="#tm-icon-download"/></svg>{{ $t('download') }}
          </button>
          <!-- if movie has been downloaded before, click to download the movie directly -->
          <a v-if="isDownloaded && !movieDetail.IsExpired && !userIsLimited && (downloadEligible && userClass === 'Download')"
            :href="file.downloadUrl"
            @click="downloadClick(file.FileName)"
            class="button button-fill button-default button-medium button-icon--left" download>
            <svg class="tm-icon"><use xlink:href="#tm-icon-download"/></svg>{{ $t('download') }}
          </a>
          <!-- disabled download button -->
          <button v-if="disableDownload" class="button-fill button-default button-medium button-icon--left"><svg class="tm-icon"><use xlink:href="#tm-icon-download"/></svg>{{ $t('download') }}</button>
        </div>
      </div>

      <!-- member / expired movie, show note -->
      <div v-if="movieDetail.IsExpired" class="download-note download-note--expired">
        <p v-if="locale == 'ja'">この動画の配信期間は終了しました。</p>
        <p v-if="locale == 'en'">This movie is currently not available.</p>
      </div>

      <!-- member/non-expired movies -->
      <div v-else>
        <!-- member / no-access (can download, but DDL/DML reached), link to DDL/DML -->
        <div v-if="userIsLimited && !dmlEnabled" class="download-note download-note--ddl">
          <p v-if="locale == 'ja'">お客様は1日のダウンロード上限に達したため、米国時間午後1時までダウンロードができなくなっております。再びアクセス可能となるまでお待ちいただくか、<router-link to="/ddl/">ダウンロード増量プラン</router-link>をご利用ください。</p>
          <p v-if="locale == 'en'">You have reached the daily download limit. Please wait until 1pm PST when you can download again, or <router-link to="/ddl/">upgrade your download plan</router-link>.</p>
        </div>
        <div v-if="downloadEligible && userIsLimited && dmlEnabled" class="download-note download-note--ddl">
          <p v-if="locale == 'ja'">お客様は1日のダウンロード上限に達したため、米国時間午後1時までダウンロードができなくなっております。再びアクセス可能となるまでお待ちいただくか、<router-link to="/dml/">ダウンロード増量プラン</router-link>をご利用ください。</p>
          <p v-if="locale == 'en'">You have reached the daily download limit. Please wait until 1pm PST when you can download again, or <router-link to="/dml/">upgrade your download plan</router-link>.</p>
        </div>

        <!-- member / no-access (ex. regular member on SVIP page), link to /upgrade/ -->
        <div v-if="!downloadEligible && showWarning" class="download-note download-note--upgrade">
          <p v-if="locale == 'ja'">この動画のダウンロードには<router-link to="/upgrade/">アップグレード</router-link>が必要です。</p>
          <p v-if="locale == 'en'">To download this video, you need to <router-link to="/upgrade/">upgrade</router-link>.</p>
        </div>
      </div>

    </div>
  </div>

  <!-- confirm download modal -->
  <modal name="ui-modal-confirm" class="modal-overwrite" draggable="false" @closed="resetConfirmModal">
    <div class="confirm-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closeConfirmModal"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_title_confirm') }}</div>
      <div class="modal-section">
        <span class="section-top">{{ $t('mdl_text_confirm_0') }}{{ slotsFree - 1 }}{{ $t('mdl_text_confirm_1') }}</span>
        <button class="button-close button-fill button-default button-small" @click="closeConfirmModal">{{ $t('mdl_btn_cancel') }}</button>
        <a :href="downloadFileUrl" @click="downloadClick(downloadFilename)" class="button button-fill button-default button-small" download>{{ $t('download') }}</a>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-lonely-if: 0 */

import axios from 'axios';
import UAParser from 'ua-parser-js';
import APP_CONFIG from '@/appConfig';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import Analytics from '@/assets/js/utils/Analytics';
import User from '@/assets/js/services/User';
import MovieHelper from '@/assets/js/utils/movie';
import Limiter from '@/assets/js/utils/limiters';

// set if this is a PPV site, which is needed by the backend to do things
const IS_PPV_SITE = false;

// broken download? Chrome 53 for Android doesn't play nice with Apache + our throttling installed
// setting this to true will call the temporary(?) servers set up with the throttling mod disabled
// apr 10 2018: still broken as of Chrome 65
const parser = new UAParser();
const ua = parser.getResult();
const BROKEN_ANDROID_DOWNLOAD = (ua.os.name === 'Android' && ua.browser.name === 'Chrome');

const REGULAR_ACCESS = ['Member', 'VIP', 'SVIP', 'DTI'];
const VIP_ACCESS = ['VIP', 'SVIP', 'DTI'];
const SVIP_ACCESS = ['SVIP', 'DTI'];

export default {
  data() {
    return {
      movieDetail: {},
      memberFiles: {},
      movieKeys: {},
      userRefreshed: false,
      downloadEligible: false, // boolean based on existence of movie keys, not to be consfused with DML's CanDownload
      showWarning: false,
      isDownloaded: false,
      downloadFilename: '',
      downloadFileUrl: '',
    };
  },
  created() {
    // non-reactive consts
    this.ua = parser.getResult();
    this.dmlEnabled = APP_CONFIG.dml.enabled;
    this.infoMap = {
      '2160p.mp4': {
        display: '2160p',
        note: '4K UHD',
      },
      '1080p.mp4': {
        display: '1080p',
        note: 'Full HD',
      },
      '720p.mp4': {
        display: '720p',
        note: 'HD',
      },
      '480p.mp4': {
        display: '480p',
        note: '',
      },
      '360p.mp4': {
        display: '360p',
        note: '',
      },
      '240p.mp4': {
        display: '240p',
        note: '',
      },
    };

    const bfAPI = new BifrostAPI();
    bfAPI.getMovieDetail(this.$route.params.movieId).then((result) => {
      const movieDetail = result;
      this.movieDetail = MovieHelper.expandDetails(movieDetail);
      if (this.movieDetail.MetaMovieID) {
        // make member file data available to the template
        this.memberFiles = this.movieDetail.MemberFiles.reverse();

        // get movie keys
        this.getKeys();

        // refresh DML state
        if (this.dmlEnabled && this.userIsSiteMember && this.userClass === 'Download') this.$store.dispatch('dml/refreshStatus', this.$route.params.movieId);
      }

      // call DML API and check download status
      this.checkDownloadStatus(movieDetail.MovieID);
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
    userClass() {
      return this.$store.getters['user/class'];
    },
    userIsLimited() {
      const isLimited = (this.dmlEnabled) ? (!this.$store.getters['dml/canDownload']) : this.$store.getters['user/isLimited'];
      return isLimited;
    },
    userTypeStr() {
      return this.$store.getters['user/typeStr'];
    },
    failsafe() {
      return this.$store.getters['dml/failsafe'];
    },
    userIsFast() {
      return this.$store.getters['user/isFast'];
    },
    disableDownload() {
      return (this.movieDetail.IsExpired || this.userIsLimited || !this.downloadEligible || this.userClass !== 'Download');
    },
    slotsFree() {
      return this.$store.getters['dml/slotsFree'];
    },
  },
  methods: {
    showLogin() {
      this.$modal.show('ui-modal-login');
    },
    checkDownloadStatus(movieId) {
      Limiter.throttle(axios.get(`${process.env.VUE_APP_DML_API}/json/detail`, { withCredentials: true }).then((response) => {
        if (response.status === 200 && response.data !== null) {
          if (Object.prototype.hasOwnProperty.call(response.data, 'Downloaded')) {
            // loop thru download history and check if it's been downloaded
            for (let i = 0; response.data.Downloaded.length > i; i += 1) {
              if (response.data.Downloaded[i].MovieID === movieId) {
                this.isDownloaded = true;
                break;
              }
            }
          }
        }
      }).catch((error) => {
        console.log('%cDML API error', 'background: #222; color: #f00', error);
      }), 5000);
    },
    downloadClick(filename) {
      // for first time download: after the link is clicked, set 'isDownloaded' flag to true to prevent confirm dialog from showing again
      if (!this.isDownloaded) {
        this.isDownloaded = true;
      }

      if (!this.userRefreshed) {
        const userSvc = new User();
        userSvc.refresh().then(() => {
          this.userRefreshed = true; // so we only request a user refresh once per movie page
        });
      }

      // send GA and other logging events
      if (this.downloadEligible && !this.userIsLimited) {
        // the .replace() below removes the file extension
        this.$analytics.trackEvent('Download', filename.replace(/\.[^/.]+$/, ''));

        // send custom GA4 event (per http://redmine.dl/issues/3383)
        Analytics.sendCustomEvent('movie_download', {
          movie_id: this.movieDetail.MovieID,
          movie_title: this.movieDetail.Title,
          actress_name: this.movieDetail.ActressesJa.join(',').replace(/^,/, ''),
          file_type: this.infoMap[filename].display,
        });

        const bfAPI = new BifrostAPI();
        bfAPI.logToNeti('DOWNLOAD_MOVIE_START', { filename });
      }

      this.closeConfirmModal();
    },
    getKeys() {
      const bfAPI = new BifrostAPI();

      function getRewriteURL(movieURL, movieID, subdomain) {
        // movie files are named [resolution].mp4 on the movie servers, but there are webserver rewrites that
        // make it so download links can be "unique" like:
        //
        // http://dlXX.1pondo.tv/member/movies/070415_109/070415_109-1pon-[resolution].mp4
        //
        // this way, users can easily save movies without risk of overwriting previously-saved movies, freeing
        // their hands to do other activities
        const rewriteStr = APP_CONFIG.downloadMenu.rewrite.siteStr;

        let rewriteURL = movieURL.replace('240p', `${movieID}-${rewriteStr}-240p`)
          .replace('360p', `${movieID}-${rewriteStr}-360p`)
          .replace('480p', `${movieID}-${rewriteStr}-480p`)
          .replace('720p', `${movieID}-${rewriteStr}-720p`)
          .replace('1080p', `${movieID}-${rewriteStr}-1080p`)
          .replace('2160p', `${movieID}-${rewriteStr}-2160p`);

        // replace subdomain if DML is enabled
        if (subdomain) {
          const urlArr = rewriteURL.split('//');
          rewriteURL = `${urlArr[0]}//`;

          const pathArr = urlArr[1].split('/');
          const hostArr = pathArr[0].split('.');
          hostArr[0] = subdomain;

          rewriteURL += `${hostArr.join('.')}/`;

          pathArr.shift();
          rewriteURL += pathArr.join('/');
        }

        return rewriteURL;
      }

      function getCustomURL(movieURL, movieID, subdomain) {
        const urlArr = movieURL.split('//');
        let customURL = `${urlArr[0]}//`;

        const pathArr = urlArr[1].split('/');
        const hostArr = pathArr[0].split('.');
        hostArr[0] = subdomain;

        customURL += `${hostArr.join('.')}/`;

        pathArr.shift();
        customURL += pathArr.join('/');

        // rewrite custom URL so files have unique names
        customURL = getRewriteURL(customURL, movieID, null);

        return customURL;
      }

      if (this.userIsSiteMember || IS_PPV_SITE) {
        bfAPI.ReqMovieKeys(this.movieDetail.MetaMovieID, IS_PPV_SITE).then((response) => {
          this.movieKeys = response;
          switch (this.movieDetail.Type) {
            case 0: {
              this.downloadEligible = (this.movieKeys.KeyMercury && (REGULAR_ACCESS.includes(this.userTypeStr) || IS_PPV_SITE));
              break;
            }
            case 1: {
              if (this.movieKeys.KeyMercury && (VIP_ACCESS.includes(this.userTypeStr) || IS_PPV_SITE)) this.downloadEligible = true;
              break;
            }
            case 2: {
              if (this.movieKeys.KeyMercury && (SVIP_ACCESS.includes(this.userTypeStr) || IS_PPV_SITE)) this.downloadEligible = true;
              break;
            }
            default: {
              break;
            }
          }
          this.showWarning = true; // now we can show any warnings now that downloadEligible has been set

          // add download URLs to the list items
          for (let i = 0; i < this.memberFiles.length; i += 1) {
            // creating this downloadUrl is the bare minimum needed to get this directive
            // working with the new BfFuncs.ReqMovieKeys. when we actually do switch over to
            // nginx video delivery, some updates will need to be done, like where to go for
            // high-speed video downloads
            const downloadUrl = (APP_CONFIG.downloadMenu.useMercKey)
              ? `${this.memberFiles[i].URL}?m=${this.movieKeys.KeyMercury}&d=.mp4`
              : `${this.memberFiles[i].URL}?u=${this.movieKeys.KeyLegacy}&d=.mp4`;

            if (BROKEN_ANDROID_DOWNLOAD) {
              // 2017-12-21: Android Chrome 53 released on 12-15 which breaks downloading from a server with
              // the bandwidth throttling apache module enabled; so to get around this, new hosts were
              // created for mobile with this module disabled
              this.memberFiles[i].downloadUrl = getCustomURL(downloadUrl, this.movieDetail[APP_CONFIG.downloadMenu.rewrite.movieIdKey], (this.dmlEnabled) ? APP_CONFIG.downloadMenu.DML[this.locale].android : APP_CONFIG.downloadMenu.DDL.android);
            } else {
              // original non-broken logic
              if (this.userIsFast) {
                this.memberFiles[i].downloadUrl = getCustomURL(downloadUrl, this.movieDetail[APP_CONFIG.downloadMenu.rewrite.movieIdKey], (this.dmlEnabled) ? APP_CONFIG.downloadMenu.DML[this.locale].highspeed : APP_CONFIG.downloadMenu.DDL.highspeed);
              } else {
                this.memberFiles[i].downloadUrl = getRewriteURL(downloadUrl, this.movieDetail[APP_CONFIG.downloadMenu.rewrite.movieIdKey], (this.dmlEnabled) ? APP_CONFIG.downloadMenu.DML[this.locale].default : null);
              }
            }
          }
        });
      }
    },
    showConfirmModal(filename, url, event) {
      event.preventDefault();
      this.downloadFilename = filename;
      this.downloadFileUrl = url;
      this.$modal.show('ui-modal-confirm');
    },
    closeConfirmModal() {
      this.$modal.hide('ui-modal-confirm');
    },
    resetConfirmModal() {
      this.downloadFilename = '';
      this.downloadFileUrl = '';
    },
    printBytes(bytes) {
      let fmtBytes = '';
      if (bytes === parseInt(bytes, 10)) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));

        // if using 'GB' or greater, format number as X.XX, otherwise just use a whole number rounding up
        fmtBytes = (i > 2)
          ? `${(bytes / (k ** i)).toPrecision(3)} ${sizes[i]}`
          : `${Math.round((bytes / (k ** i)))} ${sizes[i]}`;
      } else {
        // invalid bytes: console log and fail silently on the frontend and show nothing instead of an obvious 'NaN' derp appearing on the page
        console.log('%cprintBytes NaN', 'color:#f00', bytes);
      }
      return fmtBytes;
    },
  },
  watch: {
    userIsSiteMember(newVal, oldVal) {
      if (newVal !== oldVal) {
        // fetch movie keys
        this.downloadEligible = false;
        this.showWarning = false;
        this.getKeys();

        // refresh DML state
        if (this.dmlEnabled && newVal && this.userClass === 'Download') this.$store.dispatch('dml/refreshStatus', this.$route.params.movieId);
      }
    },
    userClass(newVal, oldVal) {
      if (newVal !== oldVal) {
        // fetch movie keys
        this.downloadEligible = false;
        this.showWarning = false;
        this.getKeys();

        // refresh DML state
        if (this.dmlEnabled && newVal && this.userClass === 'Download') this.$store.dispatch('dml/refreshStatus', this.$route.params.movieId);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
