<i18n>
{
  "en": {
    "related_videos": "Related Videos"
  },
  "ja": {
    "related_videos": "関連動画"
  }
}
</i18n>

<template>
<div class="movie-related section divider">
  <h2>{{ $t('related_videos') }}</h2>
  <div class="list list--compact">
    <div class="flex-grid">
      <!-- movie item -->
      <movie-thumbnail
        v-for="movie in movieListRelated"
        :key="movie.MovieID"
        :movie="movie"
        theme="movies-related" />
      <!-- /movie item -->
    </div>
  </div>
  <button @click="loadMore" class="button-flat button-medium button-icon--right see-more" v-show="!isDisabled">もっと見る<svg class="tm-icon"><use xlink:href="#tm-icon-arrow-drop-down"/></svg></button>
</div>
</template>

<script>
/* eslint max-len: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieHelper from '@/assets/js/utils/movie';
import movieThumbnail from '@/components/_common/movieThumbnail.vue';

export default {
  props: {
    numMovies: Number,
  },
  components: {
    'movie-thumbnail': movieThumbnail,
  },
  data() {
    return {
      movieListRelated: [],
      isDisabled: false,
    };
  },
  created() {
    this.getRelatedMovies(this.userTypeID);
  },
  methods: {
    async getRelatedMovies(userTypeID) {
      const bfAPI = new BifrostAPI();
      // get movie data + related movie info if we're on a movie detail page
      if (this.$route.name === 'movie-detail') {
        const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
        if (Object.prototype.hasOwnProperty.call(movie, 'MetaMovieID')) {
          const cats = await bfAPI.getCatAssoc(movie.MetaMovieID);
          const movieListRelated = MovieHelper.expandDetailsList(cats.Rows.slice(0, parseInt(this.numMovies, 10)), this.locale, userTypeID);
          this.movieListRelated = MovieHelper.filterExcludedMovieList(movieListRelated, userTypeID);
        }
      }
    },
    async loadMore() {
      const bfAPI = new BifrostAPI();

      const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
      if (Object.prototype.hasOwnProperty.call(movie, 'MetaMovieID')) {
        const cats = await bfAPI.getCatAssoc(movie.MetaMovieID);
        const movieListRelated = MovieHelper.expandDetailsList(cats.Rows, this.locale, this.userTypeID);
        this.movieListRelated = MovieHelper.filterExcludedMovieList(movieListRelated, this.userTypeID);
      }
      // hide "view more after click"
      this.isDisabled = !this.isDisabled;
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    userTypeID() {
      return this.$store.getters['user/typeID'];
    },
  },
  watch: {
    userTypeID(newVal) {
      this.getRelatedMovies(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
