module.exports = {
  movieGallery: {
    sampleURLs: {
      preview: '/assets/sample/{MOVIE_ID}/thum_106/{FILENAME}.jpg',
      fullsize: '/assets/sample/{MOVIE_ID}/popu/{FILENAME}.jpg',
      movieIdKey: 'MovieID',
    },
    memberURLs: {
      preview: '/assets/sample/{MOVIE_ID}/thum_106/{FILENAME}.jpg',
      fullsize: '/assets/member/{MOVIE_ID}/popu/{FILENAME}.jpg',
      movieIdKey: 'MovieID',
    },
  },
};
