<i18n>
{
  "en": {
  },
  "ja": {
  }
}
</i18n>

<template>
<div class="movie-options">
  <playlist-menu v-if="playlistEnabled" />
  <vrack-download-button v-if="isLoggedIn && vrackEnabled" />
</div>
</template>

<script>
/* eslint max-len: 0 */

import playlistMenu from '@/components/movieDetail/playlistMenu.vue';
import vrackDownloadButton from '@/components/movieDetail/vrackDownloadButton.vue';

export default {
  components: {
    'playlist-menu': playlistMenu,
    'vrack-download-button': vrackDownloadButton,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    playlistEnabled() {
      return this.$store.getters['playlist/isEnabled'];
    },
    vrackEnabled() {
      return this.$store.getters['vrack/enabled'];
    },
  },
};
</script>

<style lang="scss">
</style>
