<template>
<div>
  <div class="section" v-for="review in movieReviews" :key="review.ReviewID">
    <div><star-rating v-model="review.UserRating" active-color="#FEB507" :increment="1" :star-size="16" :inline="true" :show-rating="false" :read-only="true"></star-rating></div>
    <div class="review-comment">{{ review.UserComment }}</div>
    <div class="review-info">
      <span class="review-info__user">by {{ review.Nickname }}</span>
      <span class="review-info__date">{{ review.Created }}</span>
    </div>
  </div>
</div>
</template>

<script>
/* eslint max-len: 0 */
/* eslint no-param-reassign: 0 */

import StarRating from 'vue-star-rating';
import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  components: {
    'star-rating': StarRating,
  },
  data() {
    return {
      movieReviews: null,
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();
    const reviews = await bfAPI.getMovieReviews(this.$route.params.movieId);
    if (reviews && Object.prototype.hasOwnProperty.call(reviews, 'Rows')) {
      this.movieReviews = reviews.Rows;
      // review json has strings for the rating value
      // convert to integers so star-rating stops complaining
      if (this.movieReviews) {
        this.movieReviews.forEach((review) => {
          review.UserRating = parseInt(review.UserRating, 10);
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
