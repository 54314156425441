<i18n>
{
  "en": {
    "input_placeholder": "Enter review",
    "submit_review": "Add review",
    "name": "Name",
    "anon_name": "Anonymous",
    "rating": "Rating",
    "submit_success": "Successfully submitted a review",
    "submit_error": "Error submitting review",
    "close_notification": "CLOSE"
  },
  "ja": {
    "input_placeholder": "レビューを入力する",
    "name": "名前",
    "anon_name": "名無しさん",
    "rating": "評価",
    "submit_review": "投稿する",
    "submit_success": "レビューを投稿しました。",
    "submit_error": "レビューの投稿に失敗しました。多重投稿の有無、必須項目 (名前や評価) の入力をご確認のうえ再度お試しください。",
    "close_notification": "閉じる"
  }
}
</i18n>

<template>
<div>
  <!-- guest/nonmembers -->
  <div v-if="!userIsSiteMember" class="review-post is-guest section">レビューを投稿するには <a href="javascript:void(0)" @click="showLogin">ログイン</a> が必要です</div>

  <!-- only site members can submit reviews -->
  <transition name="review-form">
  <!-- only site members can submit reviews -->
  <div v-if="userIsSiteMember && review.enabled && review.showForm" class="section review-post">
    <textarea class="post-comment" v-if="userNickname" :placeholder="$t('input_placeholder')" v-model="review.body"></textarea>
    <!-- user has no nickname -->
    <div v-if="!userNickname" class="post-info">
      <span class="post-info__title">レビューを投稿するにはニックネームの作成が必要です » <a href="https://sns.d2pass.com/profile/update_intro/" target="_blank" rel="noopener">作成する</a></span>
    </div>

    <!-- user has nickname -->
    <div v-if="userNickname" class="post-info">
      <!-- nickname -->
      <div class="post-info__item">
        <span class="post-info__title">{{ $t('name') }}:</span>
        <span class="post-info__user">{{ (!userIsAnon) ? userNickname : $t('anon_name') }}</span>
      </div>
      <!-- star-rating: see https://github.com/craigh411/vue-star-rating for config options -->
      <div class="post-info__item">
        <span class="post-info__title">{{ $t('rating') }}:</span>
        <span class="post-info__rating rating"><star-rating v-model="review.rating" active-color="#FEB507" :star-size="16" :inline="true" :show-rating="false"></star-rating></span>
      </div>
      <!-- review submit -->
      <div class="post-info__item post-info__submit">
        <button
          @click="submitReview()"
          class="button-fill button-default button-small"
          :disabled="review.submit.started || review.body === '' || review.rating === 0 || review.submit.error || review.submit.added">
            {{ $t('submit_review') }}
        </button>
      </div>
      <div v-if="review.submit.started" class="spinner"></div>
    </div>
  </div>
  </transition>
</div>
</template>

<script>
/* eslint max-len: 0 */

import StarRating from 'vue-star-rating';
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';

export default {
  components: {
    'star-rating': StarRating,
  },
  data() {
    return {
      movieReviews: null,
      review: {
        enabled: false,
        movieDateId: null,
        showForm: true,
        rating: 0,
        body: '',
        submit: {
          started: false,
          added: false,
          error: false,
        },
      },

      // for closing the review form after submitting after 'timeout' period
      interval: null,
      numTries: 0,
    };
  },
  computed: {
    userIsSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    userIsAnon() {
      return this.$store.getters['user/isAnon'];
    },
    userNickname() {
      return this.$store.getters['user/nickname'];
    },
  },
  methods: {
    async showForm(userNickname) {
      const bfAPI = new BifrostAPI();
      const reviews = await bfAPI.getMovieReviews(this.$route.params.movieId);
      if (reviews && Object.prototype.hasOwnProperty.call(reviews, 'Rows')) {
        this.movieReviews = reviews.Rows;
        // show form if the user hasn't submitted a review for the movie
        this.review.showForm = !(this.movieReviews.some(data => data.Nickname === userNickname));
      }
    },
    showLogin() {
      this.$modal.show('ui-modal-login');
    },
    clearAlert() {
      this.review.submit.started = false;
      this.review.submit.error = false;

      // hide form if the user added a review
      if (this.review.submit.added) this.review.enabled = false;
    },
    resetReview() {
      this.review.rating = 0;
      this.review.body = '';

      this.clearAlert();
    },
    async submitReview() {
      // stop refresh() interval loop if it's running
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }

      if (this.review.enabled && this.review.body && this.review.rating) {
        this.review.submit.started = true;

        // give up on waiting for backend response after a timeout period
        this.interval = setInterval(() => {
          this.numTries += 1;
          if (this.numTries >= this.maxTries) {
            // timer housekeeping
            clearInterval(this.interval);
            this.interval = null;

            // fake a success and close the form
            this.review.submit.added = true;
            EventBus.$emit('notification:event:generic', {
              text: this.$t('submit_success'),
              duration: 4000,
            });
            this.clearAlert();
          }
        }, 100);

        // send review data to backend
        const bfAPI = new BifrostAPI();
        const result = await bfAPI.addMovieReview(this.review.movieDateId, this.review.body, this.review.rating);
        if (result.Added) {
          this.review.submit.added = true;
          EventBus.$emit('notification:event:generic', {
            text: this.$t('submit_success'),
            duration: 4000,
          });
        } else {
          this.review.submit.error = true;
          EventBus.$emit('notification:event:generic', {
            text: this.$t('submit_error'),
            duration: 10000,
          });
        }
        this.clearAlert();

        // reset timeout interval
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  },
  async created() {
    // non-reactive consts
    this.maxTries = 35; // 3.5 seconds

    const bfAPI = new BifrostAPI();
    const movie = await bfAPI.getMovieDetail(this.$route.params.movieId);
    if (movie.MovieID) {
      this.review.enabled = true;
      this.review.movieDateId = movie.MovieID;
    }
  },
  watch: {
    userIsSiteMember(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetReview();
      }
    },
    userNickname(newVal) {
      this.showForm(newVal);
      console.log('%cuserNickname', 'background: #f99; color: #eee', newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
// review form animation
.review-form-enter-active, .review-form-leave-active {
  transition: opacity 1s;
}
.review-form-enter, .review-form-leave-to {
  opacity: 0;
}

// spinner
.spinner {
  height: 2vh;
  width: 2vh;
  border: 3px solid rgba(245 ,1, 2, 0.2);
  border-top-color: rgba(245, 1, 2, 0.8);
  border-radius: 100%;
  animation: rotation 0.6s infinite linear 0.25s;

  /* the opacity is used to lazyload the spinner, see animation delay */
  /* this avoid the spinner to be displayed when visible for a very short period of time */
  opacity: 0;
}
@keyframes rotation {
  from {
    opacity: 1;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(359deg);
  }
}
</style>
