<i18n>
{
  "en": {
    "gallery_title": "Photo Gallery",
    "gallery_zip": "Gallery Download (zip)"
  },
  "ja": {
    "gallery_title": "画像ギャラリー",
    "gallery_zip": "画像一括ダウンロード"
  }
}
</i18n>

<template>
<div class="movie-gallery section divider" v-if="hasGallery">
  <div class="heading">
    <h2>{{ $t('gallery_title') }}</h2>
    <span v-if="!newXPEnabled && isSiteMember && (movieDetail.HasMemberGalleryZip || movieDetail.Gallery) && movieKey">
      <a v-if="!legacyGallery" class="button button-flat button-icon--left button-medium gallery-zip" :href="`/dyn/dla/images/movie_gallery/member/${movieDetail.MovieID}/gallery.zip?m=${movieKey}`"><svg class="tm-icon"><use xlink:href="#tm-icon-download"/></svg>{{ $t('gallery_zip') }}</a>
      <a v-if="legacyGallery" class="button button-flat button-icon--left button-medium gallery-zip" :href="`/assets/member/${movieDetail.MovieID}/gallery.zip?m=${movieKey}`"><svg class="tm-icon"><use xlink:href="#tm-icon-download"/></svg>{{ $t('gallery_zip') }}</a>
    </span>
  </div>
  <div class="gallery">
    <div class="flex-grid">
      <!-- gallery image item -->
      <div class="grid-item" v-for="photo in galleryRows" v-bind:key="photo.idx">
        <div class="gallery-ratio">
          <!-- enabled image -->
          <div class="gallery-image-wrap" v-if="photo.canViewFull === true">
            <img v-if="locale == 'ja'" class="gallery-image pointer" v-once v-img="{ group: movieDetail.MovieID, title: movieDetail.Title, src: photo.FullsizeURL, sourceButton: false }" :src="`${photo.PreviewURL}`" loading="lazy" v-sfw />
            <img v-if="locale == 'en'" class="gallery-image pointer" v-once v-img="{ group: movieDetail.MovieID, src: photo.FullsizeURL, sourceButton: false }" :src="`${photo.PreviewURL}`" loading="lazy" v-sfw />
          </div>
          <!-- disabled image -->
          <div class="gallery-image-wrap is-disabled" v-if="photo.canViewFull === false">
            <img class="gallery-image" v-once :src="`${photo.PreviewURL}`" loading="lazy" v-sfw />
          </div>
        </div>
      </div>
      <!-- /gallery image item -->
    </div>
  </div>
  <!-- <button class="button-flat button-medium button-icon--right see-more">もっと見る<svg class="tm-icon"><use xlink:href="#tm-icon-arrow-drop-down"/></svg></button> -->
</div>
</template>

<script>
/* eslint max-len: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';
import MovieDetailCfg from './config';

export default {
  data() {
    return {
      movieDetail: {},
      movieKey: null,
      gallery: [],
      hasGallery: false, // true if movie details has either a DL Admin gallery or imageimporter gallery
      legacyGallery: false, // true if using gallery from imageimporter + phpauto
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    newXPEnabled() {
      return (process.env.VUE_APP_NEW_XP_ENABLED === 'true');
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    galleryRows() {
      return this.gallery.Rows;
    },
  },
  watch: {
    async isSiteMember(newVal, oldVal) {
      if (newVal !== oldVal && this.hasGallery) {
        if (newVal) {
          // logged in
          const bfAPI = new BifrostAPI();

          const movieKeys = await bfAPI.ReqMovieKeys(this.movieDetail.MetaMovieID);
          this.movieKey = movieKeys.KeyMercury;
          this.buildGallery(this.movieDetail, this.movieKey);
        } else {
          // logged out
          this.buildGallery(this.movieDetail);
        }
      }
    },
  },
  methods: {
    buildGallery(movieDetail, movieKey = false) {
      // set a full url (as a prefix to be interpolated later) for hostnames that need it
      let protectedImagePre = '';
      Object.keys(this.bouncerHostnameMap).forEach((hostname) => {
        if (window.location.hostname.match(hostname)) {
          protectedImagePre = this.bouncerHostnameMap[hostname];
        }
      });

      // walk thru movie gallery. we use this.$set to make the list reactive instead of just
      // assigning the property values using '='. this is because Vue can't detect changes on
      // property additions or deletions
      // (see https://vuejs.org/v2/guide/reactivity.html)
      if (this.hasGallery && Object.prototype.hasOwnProperty.call(this.gallery, 'Rows')) {
        if (!this.legacyGallery) {
          // gallery created by DL Admin
          for (let i = 0; i < this.gallery.Rows.length; i += 1) {
            this.$set(this.gallery.Rows[i], 'idx', i);

            let hasFullPermissions = false;
            if (!movieDetail || (movieDetail && movieKey)) hasFullPermissions = true;

            const canViewFull = (hasFullPermissions || !this.gallery.Rows[i].Protected);
            this.$set(this.gallery.Rows[i], 'canViewFull', canViewFull);

            const PreviewURL = `/dyn/dla/images/${this.gallery.Rows[i].Img}`;
            let FullsizeURL = `${protectedImagePre}/dyn/dla/images/${this.gallery.Rows[i].Img}`;

            this.$set(this.gallery.Rows[i], 'PreviewURL', PreviewURL.replace('member', 'sample').replace(/\.jpg/, '__@120.jpg'));
            this.$set(this.gallery.Rows[i], 'FullsizeURL', FullsizeURL);

            // append movie key for site members
            if (this.gallery.Rows[i].Protected && canViewFull && movieKey) this.$set(this.gallery.Rows[i], 'FullsizeURL', FullsizeURL += `?m=${movieKey}`);
          }
        } else {
          // gallery created by dlimporter + PHPAUTO
          for (let i = 0; i < this.gallery.Rows.length; i += 1) {
            this.$set(this.gallery.Rows[i], 'idx', i);

            let hasFullPermissions = false;
            if (!movieDetail || (movieDetail && movieKey)) hasFullPermissions = true;

            const canViewFull = (hasFullPermissions || !this.gallery.Rows[i].Protected);
            this.$set(this.gallery.Rows[i], 'canViewFull', canViewFull);

            let PreviewURL;
            let FullsizeURL;
            if (movieDetail && Object.prototype.hasOwnProperty.call(MovieDetailCfg, 'movieGallery')) {
              if (this.gallery.Rows[i].Protected && this.isSiteMember) {
                PreviewURL = MovieDetailCfg.movieGallery.memberURLs.preview
                  .replace(/\{MOVIE_ID\}/g, movieDetail[MovieDetailCfg.movieGallery.memberURLs.movieIdKey])
                  .replace(/\{FILENAME\}/g, i + 1);
                FullsizeURL = MovieDetailCfg.movieGallery.memberURLs.fullsize
                  .replace(/\{MOVIE_ID\}/g, movieDetail[MovieDetailCfg.movieGallery.memberURLs.movieIdKey])
                  .replace(/\{FILENAME\}/g, i + 1);
              } else {
                PreviewURL = MovieDetailCfg.movieGallery.sampleURLs.preview
                  .replace(/\{MOVIE_ID\}/g, movieDetail[MovieDetailCfg.movieGallery.sampleURLs.movieIdKey])
                  .replace(/\{FILENAME\}/g, i + 1);
                FullsizeURL = MovieDetailCfg.movieGallery.sampleURLs.fullsize
                  .replace(/\{MOVIE_ID\}/g, movieDetail[MovieDetailCfg.movieGallery.sampleURLs.movieIdKey])
                  .replace(/\{FILENAME\}/g, i + 1);
              }
            }
            this.$set(this.gallery.Rows[i], 'PreviewURL', PreviewURL);
            this.$set(this.gallery.Rows[i], 'FullsizeURL', FullsizeURL);

            // append movie key for site members
            if (this.gallery.Rows[i].Protected && canViewFull && movieKey) {
              this.$set(this.gallery.Rows[i], 'FullsizeURL', `${protectedImagePre}${FullsizeURL}?m=${movieKey}`);
            }
          }
        }
      }
    },
  },
  async created() {
    // non-reactive consts
    // workaround for bouncer limitation only allowing 1 domain per site ID; where we need to
    // load the bouncer-protected image from the primary domain instead of using a relative url
    this.bouncerHostnameMap = {
      // regex hostname match: full url to get protected images
      'en.1pondo.tv': '//www.1pondo.tv',
      'stg.en.1pondo.tv': '//stg.1pondo.tv',
      'stg-en.1pondo.tv': '//stg.1pondo.tv',
      'dev.en.1pondo.tv': '//dev.1pondo.tv',
      'dev-en.1pondo.tv': '//dev.1pondo.tv',
    };

    const bfAPI = new BifrostAPI();
    this.movieDetail = await bfAPI.getMovieDetail(this.$route.params.movieId);

    if (this.movieDetail.MovieID) {
      if ((Object.prototype.hasOwnProperty.call(this.movieDetail, 'Gallery') && this.movieDetail.Gallery)) {
        this.hasGallery = true;
      } else if (this.movieDetail.HasGallery) {
        this.hasGallery = true;
        this.legacyGallery = true;
      }

      this.gallery = await bfAPI.getMovieGallery(this.movieDetail.MovieID, this.legacyGallery);
      if (this.isSiteMember) {
        const movieKeys = await bfAPI.ReqMovieKeys(this.movieDetail.MetaMovieID);
        this.movieKey = movieKeys.KeyMercury;
        this.buildGallery(this.movieDetail, this.movieKey);
      } else {
        this.buildGallery(this.movieDetail);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>
