<i18n>
{
  "en": {
    "add_to_vrack": "Save to V-RACK",
    "saving_in_progress": "Saving...",
    "saved_to_vrack": "Saved to V-RACK",
    "ntf_movie_add_success": "Movie saved to V-RACK",
    "ntf_error": "VRack Error",
    "mdl_save_title": "Save to V-RACK?",
    "mdl_save_0": "You have",
    "mdl_save_slots": "{availableSlots} saves | {availableSlots} save | {availableSlots} saves",
    "mdl_save_1": "remaining today",
    "mdl_save_2": "(Total {maxSlots}) | (Total {maxSlots}) | (Total {maxSlots})",
    "mdl_please_wait": "Saving...",
    "mdl_btn_cancel": "Cancel",
    "mdl_btn_save_to_vrack": "Save to V-RACK"

  },
  "ja": {
    "add_to_vrack": "V-RACKに保存する",
    "saving_in_progress": "保存中…",
    "saved_to_vrack": "V-RACKに保存済み",
    "ntf_movie_add_success": "V-RACKに保存しました",
    "ntf_error": "VRack Error",
    "mdl_save_title": "V-RACKに保存しますか？",
    "mdl_save_0": "本日の保存数は残り",
    "mdl_save_slots": "{availableSlots} | {availableSlots} | {availableSlots}",
    "mdl_save_1": "本です",
    "mdl_save_2": "(全{maxSlots}本) | (全{maxSlots}本) | (全{maxSlots}本)",
    "mdl_please_wait": "保存中…",
    "mdl_btn_cancel": "キャンセル",
    "mdl_btn_save_to_vrack": "V-RACKに保存する"
  }
}
</i18n>

<template>
<div>
  <!--
    possible button states:
      - logged in + member + not saved:       default active "add to vrack" button
      - logged in + nonmember + not saved:    default disabled "add to vrack" button
      - logged in + member/nonmember + saved: ghost disabled "saved to vrack" button
  -->
  <button
    :disabled="!vrackButtonEnabled"
    @click="vrackBtnClick"
    class="button button-default button-medium button-icon--left"
    :class="{
        'button-vrack-saving': savingInProgress,
        'button-fill': (!savingInProgress && !savedToVRack),
        'button-ghost': (!savingInProgress && savedToVRack)
    }">
    <svg class="tm-icon">
      <use :xlink:href="(savedToVRack) ? '#tm-icon-cloud' : '#tm-icon-download'"/>
    </svg>
    {{ (savingInProgress) ? $t('saving_in_progress') : (savedToVRack) ? $t('saved_to_vrack') : $t('add_to_vrack') }}
  </button>

  <!-- confirm download modal -->
  <modal name="modal-confirm-save" class="modal-overwrite" draggable="false">
    <div class="confirm-modal">
      <div class="modal-close">
        <span class="modal-close-icon" @click="closeModal('modal-confirm-save')"></span>
      </div>
      <div class="modal-title">{{ $t('mdl_save_title')}}</div>
      <div class="modal-section" v-if="availableSlots !== null">
        <span class="section-top" v-if="!savingInProgress">
          {{ $t('mdl_save_0') }} {{ $tc('mdl_save_slots', availableSlots, {availableSlots}) }} {{ $t('mdl_save_1') }} {{ $tc('mdl_save_2', maxSlots, {maxSlots}) }}
        </span>
        <span class="section-top" v-else-if="savingInProgress">
          {{ $t('mdl_please_wait') }}
        </span>
        <button
          :disabled="savingInProgress"
          class="button-close button-fill button-default button-small"
          @click="closeModal('modal-confirm-save')">
          {{ $t('mdl_btn_cancel') }}
        </button>
        <button
          :disabled="savingInProgress"
          @click="addMovieToVRack"
          class="button button-fill button-default button-small">
          {{ $t('mdl_btn_save_to_vrack' )}}
        </button>
      </div>
    </div>
  </modal>
</div>
</template>

<script>
/* eslint max-len: 0 */
import BifrostAPI from '@/assets/js/services/Bifrost/API';
import EventBus from '@/assets/js/utils/EventBus';
import MovieHelper from '@/assets/js/utils/movie';
import VRackHelper from '@/assets/js/utils/VRack';

const bfAPI = new BifrostAPI();

export default {
  data() {
    return {
      savingInProgress: false,
      savedToVRack: false,
      saveCheckComplete: false, // needed to prevent flashing of the button from on to off on load
      availableSlots: null,

      movieId: this.$route.params.movieId,
    };
  },
  async created() {
    const movieDetail = await bfAPI.getMovieDetail(this.movieId);
    this.movieDetail = MovieHelper.expandDetails(movieDetail);

    // check if movie has been added to vrack and get limit info
    if (this.vrackReady && this.isLoggedIn) this.getMovieInfo();
  },
  methods: {
    getMovieInfo() {
      VRackHelper.getLimitsInfo().then((response) => {
        this.availableSlots = response.leftCount;
      }).catch((err) => {
        console.error(err);
      });

      VRackHelper.getMovieInfo(this.movieDetail.MetaMovieID).then((response) => {
        this.savedToVRack = (response.movieId === this.movieDetail.MetaMovieID && response.hasLoaded);
        this.saveCheckComplete = true;
      });
    },
    vrackBtnClick() {
      if (this.savedToVRack) {
        // open vrack widget highlighting the current movie
        VRackHelper.showShelf({ movieId: this.movieDetail.MetaMovieID, referrer: 'save-button' });
      } else {
        // get vrack limits info and show confirmation modal
        VRackHelper.getLimitsInfo().then((response) => {
          this.availableSlots = response.leftCount;
          this.$modal.show('modal-confirm-save');
        }).catch((err) => {
          console.error(err);
        });
      }
    },
    addMovieToVRack() {
      this.savingInProgress = true;

      // check limits again for reals (ignoring cached result)
      VRackHelper.getLimitsInfo(true).then(async (response) => {
        if (response.leftCount && response.leftCount > 0) {
          // get VRack token
          let { vrackToken } = this;
          if (!vrackToken) {
            const result = await bfAPI.getVRackMovieToken(this.movieDetail.MetaMovieID);
            vrackToken = result.Token;
          }

          // save token to store (if we need to renew it)
          this.$store.dispatch('vrack/setToken', vrackToken);

          this.$modal.hide('modal-confirm-save');

          // request to save movie to vrack
          VRackHelper.addMovie(this.movieDetail.MetaMovieID, this.movieDetail, vrackToken).then((payload) => {
            // all good, movie saved to vrack
            this.$store.dispatch('vrack/setToken', null);
            setTimeout(() => {
              console.log('%caddMovie success!', 'color:#fff', payload);
              this.savingInProgress = false;
              this.savedToVRack = true;

              EventBus.$emit('notification:event:vrack', {
                text: this.$t('ntf_movie_add_success'),
                duration: 5000,
              });
            }, 8000); // delay so animation sticks around during vrack's widget animation
          }).catch((err) => {
            // error returned, show notification
            bfAPI.renewVRackMovieToken(this.vrackToken);
            setTimeout(() => {
              console.log('%caddMovie error!', 'color:#fff', err);
              this.savingInProgress = false;

              EventBus.$emit('notification:event:vrack', {
                text: (err.reason && err.reason !== '') ? err.reason : this.$t('ntf_error'),
                duration: 8000,
              });
            }, 1000);
          });
        } else {
          // no download slots available; show error
          console.log('%c no slots available :()', 'color:#fff');
          this.savingInProgress = false;
          this.$modal.hide('modal-confirm-save');
        }
      }).catch((err) => {
        console.error(err);
      });
    },
    closeModal(modalName) {
      this.$modal.hide(modalName);
    },
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    isLoggedIn() {
      return this.$store.getters['user/isLoggedIn'];
    },
    isSiteMember() {
      return this.$store.getters['user/isSiteMember'];
    },
    vrackReady() {
      return this.$store.getters['vrack/isReady'];
    },
    vrackToken() {
      return this.$store.getters['vrack/token'];
    },
    maxSlots() {
      return this.$store.getters['vrack/maxCount'];
    },
    vrackButtonEnabled() {
      return this.vrackReady && this.saveCheckComplete && !this.savingInProgress // required states
        && ((this.isSiteMember && this.savedToVRack) // site member: saved to vrack
          || (this.isSiteMember && !this.savedToVRack && this.availableSlots > 0) // or site member: not saved yet + available slots
          || (this.isLoggedIn && !this.isSiteMember && this.savedToVRack)); // or logged-in + non site-member: saved to vrack
    },
  },
  watch: {
    vrackReady(newVal, oldVal) {
      if (newVal && (newVal !== oldVal) && this.isLoggedIn) this.getMovieInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-modal {
  max-width: 320px;

  .modal-section {
    overflow-y: auto;
  }
}
</style>
